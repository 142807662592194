
@import "../../node_modules/@coreui/coreui/scss/functions";


// Here you can add other styles
.sdo-inline {
  margin-bottom: -2em;
  margin-inline: auto;
}

.horizontal-slider {
  width: 100%;
  max-width: 900px;
  height: 35px;
 // margin-left: 4em;

}

.horizontal-slider1 {
  width: 100%;
//  max-width: 900px;
  height: 35px;
  // margin-left: 4em;

}
.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: #3399ff;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
}

.example-thumb-1 {
  background-color: #321fdb;
  z-index: 4!important;
}

.route-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: #3399ff;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
}

.route-thumb-1 {
  background-color: #3399ff;
  z-index: 4!important;
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ebedef;
}

.example-track.example-track-1 {
  background: #3399ff;
}

.example-track.example-track-2 {
  background: #9da5b1;
}

.horizontal-slider .example-track {
  top: 15px;
  height: 10px;
}

.horizontal-slider .example-thumb {
  top: 7px;
  width: 50px;
  height: 23px;
  line-height: 23px;

}.horizontal-slider .route-thumb {
  top: 7px;
  width: 50px;
  height: 23px;
  line-height: 23px;
}

.horizontal-slider1 .example-track {
  top: 15px;
  height: 10px;
}

.horizontal-slider1 .example-thumb {
  top: 7px;
  width: 50px;
  height: 23px;
  line-height: 23px;

}.horizontal-slider1 .route-thumb {
  top: 7px;
  width: 50px;
  height: 23px;
  line-height: 23px;
}

.vertical-slider .example-thumb {
  left: 1px;
  width: 48px;
  line-height: 40px;
  height: 50px;
}

.vertical-slider .example-track {
  left: 20px;
  width: 10px;
}
.k-animation-container {
  z-index: 10005 !important;
}

.k-input-solid:focus-within {
  border-color: rgb(159,152,227) !important;
  box-shadow: 0 0 0 0.25em rgb(159,152,227, 50%);
}
.k-grid-toolbar {
  justify-content: flex-end !important;
}



.vertical-table-header-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}



//============================== переопределение параметров шаблона  ====================================================================

$tooltip-max-width:                 400px;


//========================================================================================================

// Required
@import "../../node_modules/@coreui/coreui/scss/variables";
@import "../../node_modules/@coreui/coreui/scss/maps";
@import "../../node_modules/@coreui/coreui/scss/mixins";
@import "../../node_modules/@coreui/coreui/scss/root";

// Optional CoreUI components here
@import "../../node_modules/@coreui/coreui/scss/tooltip";

//=========================================================================================================

.tooltip-inner {
  text-align: left;
}
.apexcharts-tooltip {
  background-color: rgb(1,1,54) !important;
  color: #f8f8f8;
}
//
//.vis-labelset .vis-label {
//  margin-top: 1em;
//}
//.vis-group {
//  margin-top: 1em;
//
//}
.vis-red {
  color: red;
}

.td_400
{
  table-layout:fixed;
  width:400px!important;
  overflow:hidden;
  word-wrap:break-word;
}

.timeline_times {
  border-right: 1px solid #ccc;
}

.br_line {
  width: 100%;
  background-color: #cccccc;
  height: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.k-picker-solid {
  background-color: #ffffff;
}

.w-805 {
  min-width: 805px;
  max-width: 805px;
}

.modal-xl {
  --cui-modal-width: 1250px;
}